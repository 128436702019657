import { useState } from 'react'
import axios from 'axios'
import cookie from 'js-cookie'

import { Button, Input, Label } from 'reactstrap'

import Box from 'components/Box'

const Entry = ({region, onRefresh}) => {
  const [isSaving, setIsSaving] = useState(false)
  const [name, setName] = useState(cookie.get('name') || '')
  const [amount, setAmount] = useState(cookie.get('amount') || '')
  const [amountToFind, setAmountToFind] = useState(cookie.get('amountToFind') || '')

  const handleSave = async () => {
    if(isSaving) {
      return
    }
    setIsSaving(true)
    await axios.post('/himmets', { region, amount, amountToFind, name })
    setIsSaving(false)
    
    onRefresh()
  }
  
  const handleNameChange = e => {
    const value = e.target.value?.toUpperCase()
    setName(value)
    cookie.set('name', value)
  }

  const handleAmountChange = e => {
    const value = e.target.value?.toUpperCase()
    setAmount(value)
    cookie.set('amount', value)
  }

  const handleAmountToFindChange = e => {
    const value = e.target.value?.toUpperCase()
    setAmountToFind(value)
    cookie.set('amountToFind', value)
  }

  const enableSaveBtn = isSaving || (amount.trim().length > 0 && name.trim().length > 0)

  return (
    <Box margin='0 auto' mt={2} width='100%' maxWidth={400} bg='#e4f1e4' p={16}>
      <Box
        display='flex'
        justifyContent='center'
        flexDirection='column'
        p={16}
        border='1px solid #85db85'
      >
      <Label>Isim</Label>
      <Box
        as={Input}
        value={name}
        disabled={isSaving}
        onChange={handleNameChange}
      />
      <Label>Vaat</Label>
      <Box
        as={Input}
        type='number'
        disabled={isSaving}
        value={amount}
        onChange={handleAmountChange}
      />
      <Label>Bulma Hedefi</Label>
      <Box
        as={Input}
        type='number'
        disabled={isSaving}
        value={amountToFind}
        onChange={handleAmountToFindChange}
      />
      <Box
        as={Button}
        mt={20}
        color='success'
        disabled={!enableSaveBtn}
        onClick={handleSave}
      >
        {!isSaving ? 'Kaydet' : 'Kaydediliyor'}
      </Box>
      </Box>
    </Box>
  )
}
export default Entry
