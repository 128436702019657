import axios from 'axios'
import { Button } from 'reactstrap'

import Box from 'components/Box'

const formatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
})

const Records = ({ records = [], onRefresh }) => {
  const handleDelete = item => () => {
    axios.delete(`/himmets/${item.region}/${item.name}`).then(() => {
      onRefresh()
    })
  }

  const totalAmount = records?.reduce((acc, cur) => acc + cur.amount, 0)
  const totalAmountToFind = records?.reduce((acc, cur) => acc + cur.amountToFind, 0)
  const recordsWithHeader = [{ isHeader: true }].concat(records)

  return (
    <Box display='flex' alignItems='center' flexDirection='column'>
      <Box as='hr' border='1px solid #28a745' width='100%' />
      <Box mt={3} fontSize={20} fontWeight='bold' textAlign='center' mb={2}>
        Vaat Toplam: {formatter.format(totalAmount)}
      </Box>
      <Box mt={3} fontSize={20} fontWeight='bold' textAlign='center' mb={2}>
        Bulma Hedefi Toplam: {formatter.format(totalAmountToFind)}
      </Box>
      <Box mt={3} fontSize={30} fontWeight='bold' textAlign='center' color='#eb145d' mb={2}>
        Toplam: {formatter.format(totalAmount + totalAmountToFind)}
      </Box>
      <Box fontSize={20} fontWeight='bold' textAlign='center' mb={20}>
        Kisi sayisi: {records.length}
      </Box>
      <Box as='hr' border='1px solid #28a745' width='100%' />
      <Box my='30px'>
        <Box as={Button} color='primary' width='300px' onClick={() => onRefresh()}>
          Sayfayi Yenile
        </Box>
      </Box>
      <Box
        border='1px solid #e4f1e4'
        mb={2}
        width='100%'
        display='flex'
        alignSelf='center'
        flexDirection='column'
        justifyContent='center'
      >
        {recordsWithHeader.map((item, i) => (
          <Box
            width='100%'
            fontSize={[20, 16, 20]}
            display='flex'
            flexDirection='row'
            justifyContent='center'
            bg={item.isHeader ? '#0a650e' : i % 2 === 0 ? '#b6e184' : '#9cd25e'}
            color={item.isHeader ? 'white' : 'inherited'}
            fontWeight={item.isHeader ? 'bold' : 'normal'}
            px={[2, 10]}
          >
            <Box width={[200, 100, 200]}>{item.isHeader ? 'Isim' : item.name}</Box>
            <Box width={[80, 60, 80]} mx={[30, 10, 40]}>
              {item.isHeader
                ? 'Vaat'
                : item.amount?.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })}
            </Box>
            <Box width={[80, 60, 80]} mx={[30, 10, 40]}>
              {item.isHeader
                ? 'Bulma'
                : item.amountToFind?.toLocaleString('en-GB', {
                    style: 'currency',
                    currency: 'GBP',
                  })}
            </Box>
            <Box
              ml={[40, 10, 60]}
              textAlign='center'
              fontWeight='bold'
              color='red'
              cursor='pointer'
              onClick={handleDelete(item)}
            >
              {item.isHeader ? <Box width='4px' /> : 'X'}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
export default Records
