import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import trim from "lodash/trim";

import { login } from "store/actions/accountActions";

import Input from "components/Input";

import {
  Button,
  ButtonWrapper,
  Container,
  ErrorMessage,
  Form,
  FormGroup,
  Label,
  Title,
} from "./SigninPage.styled";
import { useHistory } from "react-router-dom";

const SigninPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const globalState = useSelector((gs) => gs.global);
  const { isAuthenticated } = useSelector((gs) => gs.account);

  const [state, setState] = useState({
    username: null,
    password: null,
  });

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/user");
    }
  }, [history, isAuthenticated]);

  const handleInputChange = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value,
    });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    dispatch(login(state.username, state.password));
  };

  const isFormFilled = () => {
    return !!trim(state.username) && !!trim(state.password);
  };
  const resetPasswordPage = () => {
    history.push("/resetPassword");
  };
  return (
    <div>
      <Container>
        <Title>Uye Giris</Title>
        <Form onSubmit={handleOnSubmit}>
          <FormGroup>
            <Label>Kullanici adi</Label>
            <Input
              id="username"
              onChange={handleInputChange}
              value={state.username}
              disabled={state.loading}
            />
          </FormGroup>
          <FormGroup>
            <Label>Sifre</Label>
            <Input
              id="password"
              onChange={handleInputChange}
              value={state.password}
              type="password"
              disabled={state.loading}
            />
          </FormGroup>
          {globalState.errorMessage && (
            <ErrorMessage>{globalState.errorMessage}</ErrorMessage>
          )}
          <ButtonWrapper>
            <span className="resetPassword" onClick={resetPasswordPage}>Sifremi Unuttum</span>
            <Button disabled={!isFormFilled()}>
              {globalState.isLoading ? "Loading" : "Giris yap"}
            </Button>
          </ButtonWrapper>
        </Form>
      </Container>
    </div>
  );
};

export default SigninPage;
